import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a2844228"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "selectedAnswer p-1"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  style: {"white-space":"pre-wrap"}
}
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "table table-striped scoring-table" }
const _hoisted_7 = ["colspan"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["colspan"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  colspan: "12",
  style: {"text-align":"end"}
}
const _hoisted_14 = { key: 3 }
const _hoisted_15 = ["src", "alt"]
const _hoisted_16 = {
  key: 4,
  class: "nps"
}
const _hoisted_17 = {
  key: 5,
  class: "mt-2"
}
const _hoisted_18 = {
  key: 0,
  class: "selectedAnswer"
}
const _hoisted_19 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoBsIcon = _resolveComponent("HakoBsIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (
				_ctx.question.type!.technicalName == 'textSingle' ||
				_ctx.question.type!.technicalName == 'textMulti'
			)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.answerRow?.answerText)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.answerRow?.answerText), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isOptionType(_ctx.question.type!.technicalName))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.id!,
              class: _normalizeClass(["mb-1 p-1", { 'selectedAnswer': _ctx.isAnswered(option.id!) }])
            }, [
              _createTextVNode(_toDisplayString(_ctx.optionName(option.translations!)) + " ", 1),
              (_ctx.textAnswer(option.id))
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.textAnswer(option.id)), 1))
                : _createCommentVNode("", true)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type!.technicalName == 'score')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("table", _hoisted_6, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  colspan: 12 - _ctx.cPointsScaleSize
                }, null, 8, _hoisted_7),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cPoints, (point) => {
                  return (_openBlock(), _createElementBlock("td", { key: point }, _toDisplayString(point), 1))
                }), 128)),
                (_ctx.question.showNoAnswerOption)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                      _createTextVNode("-"),
                      (!!_ctx.cNoAnswerText)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, "*"))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.scorables, (scorable) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: scorable.id!
                }, [
                  _createElementVNode("td", {
                    colspan: 12 - _ctx.cPointsScaleSize,
                    class: "align-left"
                  }, _toDisplayString(_ctx.scorableText(scorable.translations!)), 9, _hoisted_10),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cPoints, (point) => {
                    return (_openBlock(), _createElementBlock("td", { key: point }, [
                      _createVNode(_component_HakoBsIcon, {
                        class: "item",
                        icon: _ctx.isScoreAnswered(scorable.id!, point, false) ? 'check-circle-fill' : 'circle',
                        theme: _ctx.isScoreAnswered(scorable.id!, point, false) ? 'success' : 'secondary'
                      }, null, 8, ["icon", "theme"])
                    ]))
                  }), 128)),
                  (_ctx.question.showNoAnswerOption)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
                        _createVNode(_component_HakoBsIcon, {
                          class: "item",
                          icon: _ctx.isScoreAnswered(scorable.id!, 0, true) ? 'check-circle-fill' : 'circle',
                          theme: _ctx.isScoreAnswered(scorable.id!, 0, true) ? 'success' : 'secondary'
                        }, null, 8, ["icon", "theme"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128)),
              (!!_ctx.cNoAnswerText)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                    _createElementVNode("td", _hoisted_13, " * " + _toDisplayString(_ctx.cNoAnswerText), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type!.technicalName == 'smiley')
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getSmileys(_ctx.question.smileyAmount!), (smiley) => {
            return (_openBlock(), _createElementBlock("span", {
              key: smiley,
              class: "ms-2"
            }, [
              _createElementVNode("img", {
                src: '/assets/survey/' + smiley + '.png',
                alt: smiley + '',
                class: _normalizeClass(["smiley", { 'selectedAnswer': _ctx.answerRow?.smileyValue == smiley }])
              }, null, 10, _hoisted_15)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type!.technicalName == 'nps')
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], (nps) => {
            return _createElementVNode("div", {
              key: nps,
              class: _normalizeClass(["nps-item", { 'selectedAnswer': _ctx.answerRow?.npsValue == nps }])
            }, _toDisplayString(nps), 3)
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.asksComments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t("comments")), 1),
          (_ctx.answerRow?.commentText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.answerRow.commentText), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t("noComments")), 1))
        ]))
      : _createCommentVNode("", true)
  ]))
}