
import { setLanguageAsync } from "@/i18n";
import { ISelectOption } from "@hakosalo/hakolib";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import axios from "axios";
import { LanguageDto } from "../../../crm2/CrmApi/TS/language-dto";

export default defineComponent({
	props: {
		title: {
			type: String as PropType<string>,
			default: "",
		},

		languages: {
			type: Array as PropType<LanguageDto[]>,
			default: () => [],
			required: false,
		},

		langFromUrl: {
			type: String as PropType<"fi" | "sv" | "en">,
			required: false,
		},

		currentPage: {
			type: Number as PropType<number>,
			required: true,
		},

		pageAmount: {
			type: Number as PropType<number>,
			required: true,
		},

		pageIndicatorColor: {
			type: String as PropType<string>,
			required: true,
		},

		// firstPageText: {
		// 	type: String as PropType<string>,
		// 	required: false,
		// },

		// ingress: {
		// 	type: String as PropType<string>,
		// 	required: false,
		// },

		surveyId: {
			type: Number as PropType<number | null | undefined>,
			required: false,
		},
	},
	setup(props) {
		const selectedLanguage = ref<"fi" | "sv" | "en">(
			props.langFromUrl ? props.langFromUrl : "fi"
		);

		const hideLogo = ref(false);

		const cLanguages = computed(() => {
			if (props.languages.length == 0) return [];

			return props.languages.map(
				(lang) =>
					({
						value: lang.code,
						text: lang.native,
					} as ISelectOption<string>)
			);
		});

		const cBaseUrl = computed(() => {
			return axios.defaults.baseURL;
		});

		const setLanguage = async () => {
			hideLogo.value = false;
			await setLanguageAsync(selectedLanguage.value);
		};

		watch(
			() => selectedLanguage.value,
			(newValue, oldValue) => {
				if (newValue != oldValue) setLanguage();
			},
			{ immediate: true }
		);

		watch(
			() => props.langFromUrl,
			(newVal, oldVal) => {
				if (newVal && newVal != oldVal) selectedLanguage.value = newVal;
			}
		);

		return {
			selectedLanguage,
			cLanguages,
			setLanguage,
			cBaseUrl,
			hideLogo,
		};
	},
});
