import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-a388e75e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "single-select"
}
const _hoisted_2 = {
  key: 1,
  class: "mt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoSingleSelect2 = _resolveComponent("HakoSingleSelect2")!
  const _component_HakoTextArea = _resolveComponent("HakoTextArea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.questionData.options && _ctx.questionData.options.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_HakoSingleSelect2, {
            modelValue: _ctx.answer,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
            placeholder: _ctx.$t('select'),
            options: _ctx.questionData.options,
            "option-generator": 
				(o) => ({
					text: o.translations?.find((y) => y.languageCode == _ctx.lang)?.text || 'error'
				})
			,
            "option-comparer": (o) => (o.id ?? 0).toString()
          }, null, 8, ["modelValue", "placeholder", "options", "option-generator", "option-comparer"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.cIsOtherOption)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createVNode(_component_HakoTextArea, {
            modelValue: _ctx.otherText,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.otherText) = $event))
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}