import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-519a852d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table table-striped scoring-table" }
const _hoisted_2 = ["colspan"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["colspan"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  colspan: "12",
  style: {"text-align":"end"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoBsIcon = _resolveComponent("HakoBsIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("td", {
            colspan: 12 - _ctx.cPointsScaleSize
          }, null, 8, _hoisted_2),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cPoints, (point) => {
            return (_openBlock(), _createElementBlock("td", { key: point }, _toDisplayString(point), 1))
          }), 128)),
          (_ctx.questionData.showNoAnswerOption)
            ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
                _createTextVNode(" -"),
                (_ctx.cHasNoAnswerText)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answers, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: 'row' + index
          }, [
            _createElementVNode("td", {
              colspan: 12 - _ctx.cPointsScaleSize,
              class: "align-left"
            }, _toDisplayString(row.scorable!.translations!.find((x) => x.languageCode == _ctx.lang)?.text), 9, _hoisted_5),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cPoints, (point) => {
              return (_openBlock(), _createElementBlock("td", { key: point }, [
                _withDirectives(_createElementVNode("span", {
                  class: "item item-active",
                  onClick: ($event: any) => {
								{
									row.points = undefined;
									row.pointsNoAnswer = false;
									_ctx.userHasAnswered = true;
								}
							}
                }, [
                  _createVNode(_component_HakoBsIcon, {
                    icon: "check-circle-fill",
                    theme: "success"
                  })
                ], 8, _hoisted_6), [
                  [_vShow, point == row.points]
                ]),
                _withDirectives(_createElementVNode("span", {
                  class: "item",
                  onClick: ($event: any) => {
								{
									row.points = point;
									row.pointsNoAnswer = false;
									_ctx.userHasAnswered = true;
								}
							}
                }, [
                  _createVNode(_component_HakoBsIcon, {
                    icon: "circle",
                    theme: "secondary"
                  })
                ], 8, _hoisted_7), [
                  [_vShow, point != row.points]
                ])
              ]))
            }), 128)),
            (_ctx.questionData.showNoAnswerOption)
              ? (_openBlock(), _createElementBlock("td", _hoisted_8, [
                  _withDirectives(_createElementVNode("span", {
                    class: "item item-active",
                    onClick: ($event: any) => {
								{
									row.points = undefined;
									row.pointsNoAnswer = false;
									_ctx.userHasAnswered = true;
								}
							}
                  }, [
                    _createVNode(_component_HakoBsIcon, {
                      icon: "check-circle-fill",
                      theme: "success"
                    })
                  ], 8, _hoisted_9), [
                    [_vShow, row.pointsNoAnswer == true]
                  ]),
                  _withDirectives(_createElementVNode("span", {
                    class: "item",
                    onClick: ($event: any) => {
								{
									row.points = undefined;
									row.pointsNoAnswer = true;
									_ctx.userHasAnswered = true;
								}
							}
                  }, [
                    _createVNode(_component_HakoBsIcon, {
                      icon: "circle",
                      theme: "secondary"
                    })
                  ], 8, _hoisted_10), [
                    [_vShow, row.pointsNoAnswer == false]
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        (_ctx.cHasNoAnswerText)
          ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
              _createElementVNode("td", _hoisted_12, " * " + _toDisplayString(_ctx.questionData.translations.find((x) => x.languageCode == _ctx.lang)?.noAnswerText), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}