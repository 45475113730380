
import {
	SurveyActivationDto,
	SurveyAnswerRowDto,
	SurveyQuestionDto,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	SurveyQuestionOptionDto,
} from "@/dto";
import { defineComponent, PropType } from "vue";
import { debounce } from "lodash";

export default defineComponent({
	props: {
		questionData: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},

		lang: {
			type: String as PropType<string>,
			required: true,
		},

		activations: {
			type: Array as PropType<SurveyActivationDto[]>,
			default: () => [] as SurveyActivationDto[],
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	data() {
		return {
			answer: null as SurveyQuestionOptionDto | null,
			allowSaving: false,
			otherText: null as string | null,
		};
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
		this.emitAnswerText = debounce(this.emitAnswerText, 200);
	},

	mounted() {
		this.answer = this.answerRow?.optionId
			? this.questionData.options?.find((x) => x.id == this.answerRow!.optionId) ?? null
			: null;
		this.otherText = this.answerRow?.optionManualAnswer
			? this.answerRow.optionManualAnswer
			: null;
		setTimeout(() => (this.allowSaving = true), 1100);

		if (this.answer?.id) this.updateActivations(this.answer.id);
	},

	computed: {
		cIsOtherOption(): boolean {
			return !!this.answer?.isOtherOption;
		},
	},

	watch: {
		answer: {
			handler(newVal, oldVal) {
				if (newVal?.id != oldVal?.id) {
					this.updateActivations(newVal?.id);

					this.emitAnswer(newVal?.id);

					const opt = this.questionData.options?.find((x) => x.id == newVal?.id);
					if (opt && !opt.isOtherOption) this.otherText = null;
				}
			},
			immediate: false,
		},

		otherText: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) {
					this.emitAnswerText(newVal);
				}
			},
		},
	},

	methods: {
		updateActivations(optionId: number) {
			let activations = this.activations.filter((x) => x.optionId == optionId);

			this.$emit("activatedActivations", activations);
		},

		emitAnswer(answer: number) {
			if (this.allowSaving) this.$emit("save", { optionId: answer });
		},

		emitAnswerText(text: string | null) {
			if (this.allowSaving)
				this.$emit("save", { optionId: this.answer?.id, optionManualAnswer: text });
		},
	},
});
