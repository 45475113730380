
import { SurveyActivationDto, SurveyAnswerRowDto, SurveyQuestionDto } from "@/dto";
import { defineComponent, PropType } from "vue";
import { debounce } from "lodash";

export default defineComponent({
	props: {
		questionData: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},

		lang: {
			type: String as PropType<string>,
			required: true,
		},

		activations: {
			type: Array as PropType<SurveyActivationDto[]>,
			default: () => [],
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	data() {
		return {
			answer: null as number | null,
			otherText: null as string | null,
			userHasAnswered: false,
		};
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
		this.emitAnswerText = debounce(this.emitAnswerText, 200);
	},

	mounted() {
		this.answer = this.answerRow?.optionId ? this.answerRow.optionId : null;

		if (this.answer) this.updateActivations(this.answer);
	},

	watch: {
		answer: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) {
					this.updateActivations(newVal);

					this.emitAnswer(newVal);

					const opt = this.questionData.options?.find((x) => x.id == newVal);
					if (opt && !opt.isOtherOption) this.otherText = null;
				}
			},
		},

		otherText: {
			handler(newVal, oldVal) {
				if (newVal != oldVal) {
					this.emitAnswerText(newVal);
				}
			},
		},
	},

	methods: {
		updateActivations(optionId: number) {
			let activations = this.activations.filter((x) => x.optionId == optionId);

			this.$emit("activatedActivations", activations);
		},

		emitAnswer(answer: number) {
			if (this.userHasAnswered) this.$emit("save", { optionId: answer });
		},

		emitAnswerText(text: string | null) {
			if (this.userHasAnswered)
				this.$emit("save", { optionId: this.answer, optionManualAnswer: text });
		},
	},
});
