import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "id", "onChange"]
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoTextArea = _resolveComponent("HakoTextArea")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionData.options?.filter((x) => x.isActive), (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.id,
        class: "form-check mb-2"
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          value: option.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answerNumbers) = $event)),
          id: 'option' + option.id,
          onChange: ($event: any) => (_ctx.checkActivations($event, option.id!))
        }, null, 40, _hoisted_1), [
          [_vModelCheckbox, _ctx.answerNumbers]
        ]),
        _createElementVNode("label", {
          for: 'option' + option.id,
          class: "ms-2"
        }, _toDisplayString(option.translations?.find((x) => x.languageCode == _ctx.lang)?.text), 9, _hoisted_2),
        (option.isOtherOption && _ctx.answerNumbers.includes(option.id!))
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
              (
							_ctx.answerDtos.find((x) => x.optionId == option.id)?.otherOptionText != undefined
						)
                ? (_openBlock(), _createBlock(_component_HakoTextArea, {
                    key: 0,
                    modelValue: _ctx.answerDtos.find((x) => x.optionId == option.id)!.otherOptionText,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.answerDtos.find((x) => x.optionId == option.id)!.otherOptionText) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}