
import {
	SurveyAnswerRowDto,
	SurveyAnswerRowScoreDto,
	SurveyQuestionDto,
	SurveyQuestionTranslationDto,
} from "@/dto";
import { debounce } from "lodash";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	props: {
		questionData: {
			type: Object as PropType<SurveyQuestionDto>,
			required: true,
		},

		lang: {
			type: String as PropType<string>,
			required: true,
		},

		answerRow: {
			type: Object as PropType<SurveyAnswerRowDto>,
		},
	},

	created() {
		this.emitAnswer = debounce(this.emitAnswer, 200);
	},

	mounted() {
		this.setupThings();
	},

	data() {
		return {
			answers: [] as SurveyAnswerRowScoreDto[],
			userHasAnswered: false,
		};
	},

	computed: {
		cPointsScaleSize(): number {
			if (!this.questionData) return 0;

			if (this.questionData.pointsMax && this.questionData.pointsMin) {
				let size = this.questionData.pointsMax! - this.questionData.pointsMin! + 1;
				if (this.questionData.showNoAnswerOption) size++;
				return size <= 10 ? size : 10;
			}

			return 0;
		},

		cPoints(): number[] {
			let arr = [];
			if (this.questionData.scoringIsReversed) {
				for (let i = this.questionData.pointsMax!; i >= this.questionData.pointsMin!; i--) {
					arr.push(i);
				}
			} else {
				for (let i = this.questionData.pointsMin!; i <= this.questionData.pointsMax!; i++) {
					arr.push(i);
				}
			}

			return arr;
		},

		cHasNoAnswerText(): boolean {
			return !!this.questionData.translations.find(
				(x: SurveyQuestionTranslationDto) => x.languageCode == this.lang
			)?.noAnswerText;
		},
	},

	methods: {
		setupThings() {
			this.questionData.scorables
				?.filter((x) => x.isActive)
				.forEach((x) => {
					this.answers.push({
						scorableId: x.id!,
						scorable: x,
						points:
							this.answerRow?.scores?.find((y) => y.scorableId == x.id)?.points ?? undefined,
						pointsNoAnswer:
							this.answerRow?.scores?.find((y) => y.scorableId == x.id)?.pointsNoAnswer ??
							false,
					});
				});
		},

		emitAnswer(answers: SurveyAnswerRowScoreDto[]) {
			let nonEmptyAnswers = answers.filter((x) => x.points != null || x.pointsNoAnswer);
			if (this.userHasAnswered) this.$emit("save", nonEmptyAnswers);
		},
	},

	watch: {
		answers: {
			handler(newVal) {
				this.emitAnswer(newVal);
			},
			immediate: false,
			deep: true,
		},
	},
});
