import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2611366a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "single-select-radio ms-3" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HakoRadio = _resolveComponent("HakoRadio")!
  const _component_HakoTextArea = _resolveComponent("HakoTextArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionData.options?.filter((x) => x.isActive), (option) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: option.id
      }, [
        _createVNode(_component_HakoRadio, {
          modelValue: _ctx.answer,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answer) = $event)),
          value: option.id,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.userHasAnswered = true))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.translations!.find((y) => y.languageCode == _ctx.lang)?.text || "error"), 1)
          ]),
          _: 2
        }, 1032, ["modelValue", "value"]),
        (option.isOtherOption && _ctx.answer && _ctx.answerRow?.optionId == option.id)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
              _createVNode(_component_HakoTextArea, {
                modelValue: _ctx.otherText,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.otherText) = $event)),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.userHasAnswered = true))
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}